@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'SF-Pro';
    src: url('./assets/font/SF-Pro-Display-Regular.otf');
    src: url('./assets/font/SF-Pro-Display-Bold.otf');
    src: url('./assets/font/SF-Pro-Display-Semibold.otf');
    src: url('./assets/font/SF-Pro-Display-Light.otf');
}

* {
  font-family: SF-Pro, "Source Sans 3", sans-serif;
  scroll-behavior: smooth;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.textShadow {
    text-shadow: 2px 2px 8px #222222;
}

.alignment {
    align-items: safe center;
}